.Menu {
  position: fixed;
  box-sizing: border-box;
  font-family: "Cormorant", sans-serif;
  padding: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  left: -30rem;
  top: 3rem;
  height: 90%;
  width: 20rem;
  z-index: 3;
  transition: left 0.5s ease-in;
  border: 2px solid lightgray;
}

.Menu.active {
  left: 0;
}

.Menu .link {
  text-decoration: none;
  color: black;
}

.Menu .socials {
  position: absolute;
  bottom: 2vh;
}

@media (max-width: 430px) {
  .Menu {
    width: 100%;
  }
}

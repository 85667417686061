@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.App {
  box-sizing: border-box;
  background-color: #f0f0f0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Main {
  position: relative;
  font-family: "Cormorant", sans-serif;
  width: 60%;
  background-size: cover;
  background-position: center;
  transition: opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Main .menuIconButton {
  position: fixed;
  left: 1vh;
  top: 0;
  z-index: 3;
}

.slide-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease;
}

.slide {
  object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.about-slide {
  background-image: url("./assets/about.jpg");
  opacity: 1;
  /* filter: blur(4px); */
}

.services-slide {
  background-image: url("./assets/services-image.jpg");
  opacity: 1;
  /* filter: blur(4px); */
}

.title-text {
  color: black;
  text-align: center;
  width: 80%;
  margin-top: 50vh;
  font-size: 35px;
  padding: 0 10%;
  z-index: 1;
}

.title-text h1 {
  margin-bottom: 0;
}

.title-text h3 {
  margin: 0;
  padding: 0;
}

.about-text,
.services-text {
  box-sizing: border-box;
  color: black;
  text-align: center;
  padding: 0 10%;
  width: 70%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-text div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  /* border: 2px solid black; */
}

.services-text ul {
  /* border: 2px solid black; */
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  padding: 0;
}

.services-text ul h3 {
  align-self: center;
}

.services-text li {
  list-style: none;
  margin-bottom: 5px;
  padding: 0;
  margin-left: 1.5rem;
}

.services-text li::before {
  content: "-"; /* Use a dash as a content before each list item */
  display: inline-block;
  width: 1em; /* Adjust the width to control spacing */
  margin-left: -1.5rem; /* Offset to align with the text */
  font-weight: bold; /* Optional: Make the dash bold */
}

.about-text h1 {
  border-bottom: 2px solid black;
}

.services-text h1 {
  border-bottom: 2px solid black;
}

@media (max-width: 1320px) {
  .services-text ul {
    width: 50%;
  }
}

@media (max-width: 1300px) {
  .services-text div {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .Main {
    /* height: 100vh; */
    width: 100%;
  }

  .title-text {
    margin-top: 25rem;
    font-size: 15px;
  }

  .about-text,
  .services-text {
    width: 100%;
  }

  .services-text {
    height: 100%;
    justify-content: center;
  }

  .services-text div {
    /* border: 2px solid black; */
    width: 70%;
  }
}

@media (max-width: 576px) {
  .Main {
    /* height: 100vh; */
    width: 100%;
  }
  .title-text {
    margin-top: 20rem;
    font-size: 15px;
  }
  .services-text div {
    /* border: 2px solid black; */
    width: 100%;
  }
}

@media (max-width: 410px) {
  .title-text {
    font-size: 14px;
  }

  .services-text ul {
    width: 60%;
  }
}

@media (max-width: 360px) {
  .title-text {
    font-size: 13px;
  }
}

@media (max-width: 280px) {
  .title-text {
    font-size: 10px;
  }
}
